import {
  AutoComplete,
  Button,
  Col,
  Input,
  Pagination,
  PaginationProps,
  Row,
  Space,
  Table,
  Tooltip,
  Typography,
  notification,
} from 'antd';
import {
  DYNAMIC_DEFAULT_FILTER,
  DYNAMIC_DEFAULT_SELECTED_FILTER,
} from '../actionable';
import {
  FilterFieldsMetaData,
  FiltersType,
  LabelPairType,
} from '../../../../types/common/filters.type';
import { IndividualApplyButton, Tab } from '../individualApplyButton';
import { PayeeEnum, PayeeEnumV2 } from '../../../../enums/payee.enum';
import axios, { CancelTokenSource } from 'axios';
import {
  getOptionsForSearch,
  getSearchDisplayText,
  getSearchTypeByLabel,
  sortSearchTypesByPage,
} from '../../../../utils/search.utils';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { ActionableLicenseResponseError } from '../../../../types/response/actionable-license.type';
import AgentAssignmentStatus from '../../../../components/licenses/agentAssignmentStatus';
import { AgentOverview } from '../../../../types/data/allAgents.type';
import { AgentService } from '../../../../services/agent.service';
import { AllLicenseResponse } from '../../../../types/response/all-license.type';
import { ApplicationStatusEnum } from '../../../../enums/applicationStatus.enum';
import { ApplicationTypeEnumInBackend } from '../../../../enums/applicationType.enum';
import ApplyLicenseErrorModal from '../../../../components/actionableLicenseModal/applyLicenseErrorModal';
import AssociatedAgent from '../../../../components/licenses/associatedAgent';
import { ColumnsType } from 'antd/es/table';
import DaysToExpireCell from '../../../../components/licenses/daysToExpireCell';
import EngineLights from '../../../../components/licenses/engineLights';
import FilterButton from '../../../../components/filterButton';
import { FilterType } from '../../../../enums/filterType.enum';
import { IdConstants } from '../../../../constants/id.constants';
import LcLoaCell from '../../../../components/licenses/lcLoaCell';
import { LicenseApplicationResponse } from '../../../../types/response/license-application.type';
import LicenseModal from '../../../../utils/modal/application-status/applictionStatus.modal';
import { LicenseStatus } from '../../../../enums/licenseStatus.enum';
import { LicensesService } from '../../../../services/licenses.service';
import { LoaDetail } from '../../../../types/data/loaDetail.type';
import { LoaStatus } from '../../../../enums/loaStatus.enum';
import { ObjectSuccessResponse } from '../../../../types/response/objectSuccessResponse.type';
import { PageConstants } from '../../../../constants/page.constants';
import { PaginatedSuccessResponse } from '../../../../types/response/paginatedSuccessResponse.type';
import PaymentConfigModal from '../../../../components/paymentConfig/paymentConfigModal';
import { PaymentTypeEnum } from '../../../../enums/paymentType.enum';
import { PlausiblePageConstants } from '../../../../constants/plausible-page.constants';
import { PortalType } from '../../../../enums/portalType.enum';
import PreApplicationErrorModal from '../../../../components/preApplicationErrorModal/preApplicationErrorModal';
import { ProducerLicenseType } from '../../../../enums/agencyLicenseType.enum';
import { RenderFilterChip } from '../../../../components/common/simpleFilter/filterOptionTypes/renderFilterChip';
import { RoleType } from '../../../../enums/roles.enum';
import { SEARCH_REGEX } from '../../../../constants/regex.constants';
import SimpleFilter from '../../../../components/common/simpleFilter/simpleFilter';
import { StateConstants } from '../../../../constants/state.constants';
import { SuccessMessageConstants } from '../../../../constants/succes-message.constants';
import activeLoaIcon from '../../../../assets/icons/success rectangle.svg';
import { adminStore } from '../../../../stores/admin.store';
import { appStateInfoStore } from '../../../../stores/appStateInfo.store';
import { applySort } from '../../../../utils/common.utils';
import { cancelPreviousRequest } from '../../../../utils/api.utils';
import error from '../../../../assets/icons/error.svg';
import { getBackgroundColor } from '../../../agents/manageAssignments/manageAssignment';
import { getNameString } from '../../../../utils/name.utils';
import { getProducerName } from '../../../agents/agentsOverview/agencyAgentOverview';
import { isEmpty } from 'lodash';
import missingLoaIcon from '../../../../assets/icons/reject rectangle.svg';
import { produce } from 'immer';
import { setFilterGroups } from '../../../../utils/setSimpleFiltersRequest.utils';
import success from '../../../../assets/icons/success.svg';
import { useAuth } from '../../../../auth/authProvider';
import { useQueryState } from '../../../../utils/sync-query-param/use-query-state';
import { withRoles } from '../../../../auth/useRoles';

const DEFAULT_SELECTED_FILTER = {
  'License Status': {
    labelValuePair: {
      value: 'active',
      label: 'Active',
    },
    key: 'status',
  },
};

const DEFAULT_FILTER = [
  {
    operator: 'or',
    filters: [
      {
        key: 'status',
        condition: 'is',
        value: 'active',
      },
    ],
  },
];

const { Search } = Input;

interface AllLicensesProps {
  isAgentOverview?: boolean;
}

const AllLicensesAgentsTab: React.FC<AllLicensesProps> = ({
  isAgentOverview,
}) => {
  const { agentId } = useParams();
  const licenseStatus = LicenseStatus.Active;
  const [searchParams, setSearchParams] = useSearchParams();

  const licenseApplicationStatus = ApplicationStatusEnum.Success;
  const [licenseCount, setLicenseCount] = useState({
    activeCount: 0,
    needsAttentionCount: 0,
    renewalsCount: 0,
  });
  const [selectedLicenseType, setSelectedLicenseType] =
    useState<ProducerLicenseType>(ProducerLicenseType.ALL);
  const [loading, setIsLoading] = useState({
    all: false,
    needsAttention: false,
    renewal: false,
  });
  const [activeData, setActiveData] = useState<AllLicenseResponse[]>([]);

  const { bearerToken, getAccessTokenSilently } = useAuth();
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [pageNumber, setPageNumber] = useState(1);
  const [cancelToken, setCancelToken] = useState<CancelTokenSource>();
  const [searchText, setSearchText] = useState('');
  const [queryText, setQueryText] = useQueryState('search');
  const [displaySearchType, setDisplaySearchType] = useState('');
  const [isFilterVisible, setIsFilterVisible] = useState<boolean>(false);
  const [filterInfoLoading, setIsFilterInfoLoading] = useState<boolean>(false);
  const [requestBody, setRequestBody] = useState<Object>({});
  const [selectedFilters, setSelectedFilters] = useState<FiltersType>({
    data: {},
  });
  const [sortValue, setSortValue] = useState({});
  const [disableAssign, setDisableAssign] = useState(false);
  const [currentAssignLoadingId, setCurrentAssignLoadingId] = useState('');
  const [searchType, setSearchType] = useState('');
  const [searchFilter, setSearchFilter] = useState([]);
  // renewal
  const [renewalLicenses, setRenewalLicenses] = useState<
    LicenseApplicationResponse[]
  >([]);
  //
  const [controlledFilters, setControlledFilters] = useState([]);
  // needs attention
  const [needsAttentionLicenses, setNeedsAttentionLicenses] = useState<
    LicenseApplicationResponse[]
  >([]);
  const [paymentConfigModalVisibility, setPaymentConfigModalVisibility] =
    useState(false);
  const [currentAgent, setCurrentAgent] = useState<AgentOverview>();
  // const [selectedLicenses, setSelectedLicenses] = useState<
  //   LicenseApplicationResponse[]
  // >([]);
  const [disableApplyButton, setDisableApplyButton] = useState(false);
  const [
    applyLicenseErrorModalVisibility,
    setApplyLicenseErrorModalVisibility,
  ] = useState<boolean>(false);
  const [errors, setErrors] = useState<ActionableLicenseResponseError[]>([]);
  const [preApplicationModalVisibility, setPreApplicationModalVisibility] =
    useState(false);
  const [preApplicationModalRecord, setPreApplicationModalRecord] =
    useState<any>();
  const [selectedLicenseDetails, setSelectedLicenseDetails] = useState<any>('');
  const [applicationModalVisible, setApplicationModalVisible] =
    useState<boolean>(false);
  //

  // error toast setup
  const [api, contextHolder] = notification.useNotification();
  const showError = (message: string) => {
    api['error']({
      message: 'Error',
      description: message,
    });
  };

  const handleRowClick = (record: any) => {
    setSelectedLicenseDetails(record);
    setApplicationModalVisible(true);
  };

  const createAssignmentForLicense = async (
    producerId: string,
    requestBody: any,
    id: string
  ) => {
    try {
      setDisableAssign(true);
      setCurrentAssignLoadingId(id);
      const token = await getAccessTokenSilently();
      const resp = await AgentService.createAssignmentForLicense(
        token,
        producerId,
        requestBody
      );
      if (resp) fetchLicenseDetails();
    } catch (e: any) {
      console.error('createAssignmentForLicense error :: ', e);
      if (e?.error?.message) showError(e.error.message);
      else showError('Assignment failed for given license');
    } finally {
      setDisableAssign(false);
      setCurrentAssignLoadingId('');
    }
  };

  // const updateRenewalPaymentAccountByAgentId = (
  //   agent: any,
  //   renewalPaymentAccount: any
  // ) => {
  //   if (agent.includes(agent._id)) {
  //     return {
  //       ...agent,
  //       paymentConfig: {
  //         ...agent.paymentConfig,
  //         renewalPaymentPayee: renewalPaymentAccount,
  //         paymentType:
  //           agent.paymentConfig?.initialPaymentPayee === 'Agent' &&
  //           renewalPaymentAccount === 'Agent'
  //             ? PaymentTypeEnum.NA
  //             : agent.paymentConfig?.paymentType,
  //       },
  //       autoRenewEnabled:
  //         renewalPaymentAccount === 'Agent' ? false : agent.autoRenewEnabled,
  //     };
  //   }
  //   return agent;
  // };

  useEffect(() => {
    if (Object.keys(requestBody).length) {
      setPageNumber(1);
      fetchLicenseDetails('', 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestBody]);

  const fetchLicenseStatusCount = async () => {
    try {
      setLicenseCount({
        activeCount: 0,
        needsAttentionCount: 0,
        renewalsCount: 0,
      });
      const token = await getAccessTokenSilently();
      const licenseStatusCount = await LicensesService.getLicenseStatusCount(
        token,
        agentId
      );
      setLicenseCount({
        activeCount: licenseStatusCount?.activeCount || 0,
        needsAttentionCount: licenseStatusCount?.needsAttentionCount || 0,
        renewalsCount: licenseStatusCount?.renewalLicensesCount || 0,
      });
    } catch (e: any) {
      console.error('fetchLicenseStatusCount error :: ', e);
    }
  };

  useEffect(() => {
    fetchLicenseStatusCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentId]);

  useEffect(() => {
    fetchLicenseDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    licenseStatus,
    adminStore.account,
    licenseApplicationStatus,
    queryText,
    searchType,
    agentId,
  ]);

  const getAgentDetails = async () => {
    const response: any = await AgentService.getAgentDetails(
      agentId || '',
      bearerToken || ''
    ).catch((error) => {
      console.error('Error:: ', error.message);
    });
    if (response.data) {
      appStateInfoStore.setCurrentAgentId(response.data.id);
      appStateInfoStore.setHeader(getNameString(response.data.name));
    }
  };

  useEffect(() => {
    if (
      !appStateInfoStore.header &&
      isAgentOverview &&
      agentId &&
      bearerToken
    ) {
      getAgentDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentId]);

  const clearFilters = () => {
    let data = {};
    if (isAgentOverview) {
      data = {
        ...Object.fromEntries(
          Object.entries(selectedFilters?.data)?.filter(
            ([k, v]) => k === 'Producer'
          )
        ),
      };
    }

    setSelectedFilters({ data });
    const filterGroups = setFilterGroups({ data });
    const updatedRequestBody = {
      ...requestBody,
      filterGroups,
    };
    setRequestBody(updatedRequestBody);
  };

  const showCustomError = (msg: { message: string; description?: string }) => {
    api['error']({
      message: msg.message,
      description: msg.description,
    });
  };

  const deleteAppliedLicense = async (licenseId: string) => {
    api['success']({
      message: 'License application submitted',
      description: SuccessMessageConstants.LICENSE_APPLICATION_IN_PROGESS,
    });
    fetchNeedsAttentionLicenses();
  };

  const setIsLoadingForGivenLicense = (
    licenseId: string,
    isLoading: boolean
  ) => {
    const newLicenses = produce(needsAttentionLicenses, (draft) => {
      draft.forEach((itr) => {
        if (itr.id === licenseId) itr.isLoading = isLoading;
      });
    });
    setNeedsAttentionLicenses(newLicenses);
  };

  const updateLicenseById = (
    licenseId: string,
    record: LicenseApplicationResponse
  ) => {
    const newLicenses = produce(needsAttentionLicenses, (draft) => {
      for (let i = 0; i < draft.length; i++) {
        if (draft[i].license.id === licenseId) {
          console.debug('found license by id :: updating');
          draft[i] = { ...record };
          break;
        }
      }
    });
    setNeedsAttentionLicenses(newLicenses);
  };

  const refreshLicenseById = async (licenseId: string) => {
    try {
      setIsLoadingForGivenLicense(licenseId, true);
      const token = await getAccessTokenSilently();

      fetchNeedsAttentionLicenses();

      const response: ObjectSuccessResponse<LicenseApplicationResponse> =
        await LicensesService.findLatestApplicationForGivenLicense(
          token,
          licenseId
        );
      if (response?.data) {
        updateLicenseById(licenseId, response.data);
      } else setIsLoadingForGivenLicense(licenseId, false);
    } catch (e) {
      setIsLoadingForGivenLicense(licenseId, false);
    }
  };

  const getApplicationStatus = (record: AllLicenseResponse) => {
    if (record?.latestApplication && record?.latestApplication?.length !== 0) {
      if (
        record?.latestApplication[0].applicationStatus ===
        ApplicationStatusEnum.PENDING
      ) {
        if (record.status === LicenseStatus.Active) {
          return 'Renewing';
        }
        if (
          [
            LicenseStatus.Inactive,
            LicenseStatus.MISSING_LOA,
            LicenseStatus.Missing,
          ].includes(record.status as LicenseStatus)
        ) {
          return 'Applied';
        }
      }
      if (
        record?.latestApplication[0].applicationStatus ===
        ApplicationStatusEnum.Declined
      ) {
        if (record.status === LicenseStatus.Active) {
          return 'Declined Renewal';
        }
        if (
          [
            LicenseStatus.Inactive,
            LicenseStatus.MISSING_LOA,
            LicenseStatus.Missing,
          ].includes(record.status as LicenseStatus)
        ) {
          return 'Declined';
        }
      }
    }
  };

  const tableColumns: ColumnsType<any> = [
    {
      title: 'State',
      dataIndex: 'stateCode',
      sorter: true,
      key: 'stateCode',
      width: 100,
      render: (text: string, record: AllLicenseResponse) => {
        const applicationStatus = getApplicationStatus(record);

        const status =
          selectedLicenseType === ProducerLicenseType.RENEWALS
            ? record?.license?.status
            : record?.status;
        return (
          <div style={{ marginLeft: 12 }}>
            <div
              className="text-base-style"
              style={{
                lineHeight: '20px',
                letterSpacing: '0.5%',
              }}
            >
              {StateConstants[text]}
            </div>
            <div
              style={{
                color: '#222222',
                borderRadius: 16,
                padding: '2px 8px',
                textTransform: 'capitalize',
                width: 'fit-content',
                fontSize: '7.32px',
                lineHeight: '8.78px',
                marginTop: 4,
                ...getBackgroundColor(status!),
              }}
            >
              {status} {applicationStatus && <>({applicationStatus})</>}
            </div>
          </div>
        );
      },
    },
    ...(agentId
      ? []
      : [
          {
            title: 'Producer Name',
            dataIndex: 'producerDetails',
            sorter: true,
            key: 'licenseProducer.name',
            width: 130,
            render: (producer: any, record: AllLicenseResponse) => {
              return (
                // <></>
                <AssociatedAgent
                  agentId={producer.producerId}
                  agentName={producer.name}
                  isLicenseProcessing={producer.isLicenseProcessing}
                  isAssignmentProcessing={producer.isAssignmentProcessing}
                  id={`${IdConstants.LICENSES.TABLE.ASSOCIATED_AGENT}-${record.licenseNumber}`}
                />
              );
            },
          },
        ]),
    {
      title: 'License Class & LOAs',
      dataIndex: 'licenseConfigurations',
      key: 'licenseConfigurationDetails.licenseClass',
      width: '330px',
      sorter: true,

      className: 'cell-with-lines agency-license-state-row center-column',
      render: (loas: any, record: any) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center !important',
              alignItems: 'flex-start',
              padding: '8px 0',
            }}
          >
            <Row style={{ width: '40%', alignContent: 'center' }}>
              <div
                style={{
                  gap: '8px',
                  width: '100%',
                  paddingRight: 12,
                  boxSizing: 'border-box',
                  flexGrow: 1,
                }}
              >
                <Tooltip
                  title={`${appStateInfoStore.getLcNameFromCode(
                    record.licenseConfigurations.licenseClass
                  )} (${record.licenseConfigurations.licenseClassCode})`}
                >
                  <Typography.Paragraph
                    style={{
                      fontSize: '12px',
                      fontWeight: 400,
                      color: '#001F45',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      cursor: 'pointer',
                      marginBottom: 0,
                    }}
                    className="margin-unset"
                    ellipsis
                  >
                    {`${appStateInfoStore.getLcNameFromCode(
                      record.licenseConfigurations.licenseClass
                    )} (${record.licenseConfigurations.licenseClassCode})`}
                  </Typography.Paragraph>
                </Tooltip>
              </div>
            </Row>

            <div
              style={{
                position: 'absolute',
                top: 0,
                left: '43%',
                bottom: 0,
                width: '1px',
                backgroundColor: '#d9d9d9',
                height: '100%',
              }}
            />

            <div
              style={{
                width: '55%',
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: '8px',
                alignSelf: 'center',
                marginLeft: '3%',
              }}
            >
              {record.licenseConfigurations.loaDetails.map(
                (loa: LoaDetail, index: number) => {
                  return (
                    <Space key={index}>
                      {loa.status?.toLocaleLowerCase() ===
                      LoaStatus.ACTIVE.toLocaleLowerCase() ? (
                        <img src={activeLoaIcon} alt="activeLoa" />
                      ) : (
                        <img src={missingLoaIcon} alt="nonActiveLoa" />
                      )}
                      <Typography.Paragraph
                        style={{
                          margin: 0,
                          textAlignLast: 'start',
                          color: '#222222',
                        }}
                        className="margin-unset"
                      >
                        {appStateInfoStore.getLoaNameFromCode(loa.loa)}
                      </Typography.Paragraph>
                    </Space>
                  );
                }
              )}
            </div>
          </div>
        );
      },
    },
    ...([
      ProducerLicenseType.NEEDS_ATTENTION,
      ProducerLicenseType.RENEWALS,
    ].includes(selectedLicenseType as ProducerLicenseType)
      ? [
          ...(selectedLicenseType === ProducerLicenseType.NEEDS_ATTENTION
            ? [
                {
                  title: 'Initial Payment',
                  dataIndex: 'paymentConfig',
                  key: 'producerDetails.paymentConfig.licenseInitialPayee',
                  sorter: !isAgentOverview,
                  width: 90,
                  align: 'center' as any,
                  render: (_: any, record: any, index: number) => {
                    return (
                      <Row
                        id={
                          IdConstants.AGENTS.AGENT_OVERVIEW.TABLE
                            .INITIAL_PAYMENT +
                          record.id +
                          '-other-line-only-agents'
                        }
                        className="text-base-style"
                      >
                        {record.producerDetails.paymentConfig
                          ?.licenseInitialPayee ? (
                          getProducerName(
                            record.producerDetails.paymentConfig
                              ?.licenseInitialPayee
                          )
                        ) : (
                          <span style={{ color: '#92A69E', fontSize: '12px' }}>
                            Payee not selected
                          </span>
                        )}
                      </Row>
                    );
                  },
                },
              ]
            : [
                {
                  title: 'Expiration Date',
                  dataIndex: 'expiryDate',
                  key: 'expiryDate',
                  sorter: true,
                  width: 150,
                  render: (text: string, record: any) => {
                    return (
                      <DaysToExpireCell
                        expiryDate={text}
                        licenseStatus={record?.license?.status as LicenseStatus}
                        licenseState={record.stateCode}
                      />
                    );
                  },
                },
              ]),
          {
            title: 'Renewal Payment',
            dataIndex: 'producerDetails',
            sorter: !isAgentOverview,
            key: 'producerDetails.paymentConfig.licenseRenewalPayee',
            width: 90,
            align: 'center' as any,
            render: (producerDetails: any, record: any, index: number) => {
              return (
                <Row
                  id={
                    IdConstants.AGENTS.AGENT_OVERVIEW.TABLE.INITIAL_PAYMENT +
                    record.id +
                    '-other-line-only-agents'
                  }
                  className="text-base-style"
                >
                  {record.producerDetails.paymentConfig?.licenseRenewalPayee ? (
                    getProducerName(
                      record.producerDetails.paymentConfig?.licenseRenewalPayee
                    )
                  ) : (
                    <span style={{ color: '#92A69E', fontSize: '12px' }}>
                      Payee not selected
                    </span>
                  )}
                </Row>
              );
            },
          },
          {
            title: 'Status Graph',
            width: 110,
            render: (record: any) => {
              return (
                <Col
                  style={{
                    rowGap: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: 12,
                  }}
                >
                  <EngineLights
                    payer={
                      record.status === 'missing'
                        ? record.producerDetails?.paymentConfig
                            ?.licenseInitialPayee
                        : record.producerDetails?.paymentConfig
                            ?.licenseRenewalPayee
                    }
                    setPayerCallBack={() => {
                      setCurrentAgent({
                        ...record.producerDetails,
                        id:
                          record.producerDetails?.producerId ||
                          record.producerDetails.producerId,
                      });
                      // initial payer missing
                      if (
                        record.applicationType ===
                        ApplicationTypeEnumInBackend.creation
                      ) {
                        if (
                          record.producerDetails?.paymentConfig
                            ?.licenseInitialPayee !== PayeeEnum.AGENCY
                        )
                          setPaymentConfigModalVisibility(true);
                        else if (
                          // payment type is missing
                          !(
                            record.producerDetails?.paymentConfig
                              ?.paymentType === PaymentTypeEnum.CAP ||
                            record.producerDetails?.paymentConfig
                              ?.paymentType === PaymentTypeEnum.FULL
                          )
                        ) {
                          setPaymentConfigModalVisibility(true);
                        }
                      } else {
                        // renewal payer missing
                        if (
                          record.producerDetails?.paymentConfig
                            ?.renewalPaymentPayee !== PayeeEnum.AGENCY
                        )
                          setPaymentConfigModalVisibility(true);
                        else if (
                          // payment type is missing
                          !(
                            record.producerDetails?.paymentConfig
                              ?.paymentType === PaymentTypeEnum.CAP ||
                            record.producerDetails?.paymentConfig
                              ?.paymentType === PaymentTypeEnum.FULL
                          )
                        ) {
                          setPaymentConfigModalVisibility(true);
                        }
                      }
                    }}
                    record={record}
                  />
                </Col>
              );
            },
          },
          {
            width: 110,
            render: (record: any) => {
              return (
                <IndividualApplyButton
                  payer={
                    record.applicationType ===
                    ApplicationTypeEnumInBackend.creation
                      ? record.producerDetails?.paymentConfig
                          ?.licenseInitialPayee
                      : record.producerDetails?.paymentConfig
                          ?.licenseRenewalPayee
                  }
                  selectedLicensesLength={0}
                  record={record}
                  currentTab={Tab.MISSING_LICENSES}
                  setRefreshData={() => {}}
                  deleteAppliedLicense={deleteAppliedLicense}
                  setDisableApplyButton={setDisableApplyButton}
                  disableApplyButton={disableApplyButton || !!record.isLoading}
                  showError={showCustomError}
                  setApplyLicenseErrorModalVisibility={
                    setApplyLicenseErrorModalVisibility
                  }
                  setErrors={setErrors}
                  setPreApplicationModalVisibility={
                    setPreApplicationModalVisibility
                  }
                  setPreApplicationModalRecord={setPreApplicationModalRecord}
                  applyErrorCallback={refreshLicenseById}
                  isV2={true}
                  onDeclinedClick={() => {
                    handleRowClick(record);
                  }}
                  isNeedsAttentionPage={
                    selectedLicenseType === ProducerLicenseType.NEEDS_ATTENTION
                  }
                />
              );
            },
          },
        ]
      : [
          {
            title: 'Auto Renew',
            dataIndex: 'autoRenews',
            key: 'producerDetails.paymentConfig.isAutoRenewalActive',
            width: 70,
            align: 'center' as any,
            sorter: true,
            render: (text: boolean, record: any) => {
              return record?.producerDetails?.paymentConfig
                ?.isAutoRenewalActive === true ? (
                <img alt="logo" src={success} />
              ) : (
                <img alt="logo" src={error} />
              );
            },
          },
          {
            title: 'Assignment Status',
            sorter: true,
            dataIndex: 'licenseConfigurations',
            key: 'licenseConfigurations.agencyAssignment.isAssigned',
            width: 140,
            onCell: (record: any) => ({
              style: {
                color: record?.licenseConfigurations?.agencyAssignment?.[0]
                  ?.isAssigned
                  ? 'green'
                  : 'red',
              },
            }),
            render: (licenseConfiguration: any, record: any) => {
              const isLoading = currentAssignLoadingId === record.id;
              return (
                <AgentAssignmentStatus
                  isAssigned={
                    licenseConfiguration?.agencyAssignment[0]?.isAssigned
                  }
                  showAssignButton={
                    !licenseConfiguration?.agencyAssignment[0]?.isAssigned &&
                    record.status === LicenseStatus.Active
                  }
                  handleClick={() => {
                    const licenseConfigurationIds =
                      record.licenseConfigurations.loaDetails.map(
                        (loa: any) => {
                          return loa.licenseConfigurationId;
                        }
                      );
                    const stateCode = record.stateCode;
                    const requestBody = { licenseConfigurationIds, stateCode };
                    createAssignmentForLicense(
                      record.producerDetails.producerId,
                      requestBody,
                      record.id
                    );
                  }}
                  isLoading={isLoading}
                  isDisabled={!isLoading && disableAssign}
                />
              );
            },
          },
        ]),
  ];

  const onPaginationChange: PaginationProps['onChange'] = (
    newPageNumber: number,
    newPageSize: number
  ) => {
    setPageSize(newPageSize);
    setPageNumber(newPageNumber > 0 ? newPageNumber : pageNumber);
    adminStore.agency?.id &&
      fetchLicenseDetails(queryText, newPageNumber, newPageSize);
  };

  const fetchFiltersInfoForFindAll: () => Promise<
    FilterFieldsMetaData[]
  > = async () => {
    try {
      setIsFilterInfoLoading(true);
      const defaultRequestBody = {
        agency: adminStore.agency?.id,
        isLatest: true,
      };
      if (bearerToken) {
        let response: any;

        if (selectedLicenseType === ProducerLicenseType.NEEDS_ATTENTION) {
          response = await LicensesService.getFilterInfoForFindActionable(
            ApplicationTypeEnumInBackend.creation,
            bearerToken,
            defaultRequestBody
          );
        } else if (selectedLicenseType === ProducerLicenseType.RENEWALS) {
          response = await LicensesService.getFilterInfoForRenewals(
            bearerToken,
            defaultRequestBody
          );
        } else {
          response = await LicensesService.getFilterInfoForFindAllLicenses(
            bearerToken,
            defaultRequestBody
          );
        }

        setSearchFilter(
          sortSearchTypesByPage(
            response?.data?.filter(
              (data: { filterType: string; key: string }) =>
                data.filterType === FilterType.SEARCH &&
                (!isAgentOverview || data.key !== 'name')
            ),
            PageConstants.ALL_PRODUCER_LICENSES
          )
        );
        const result = isAgentOverview
          ? response?.data?.filter((data: any) => data.key !== 'producerIds')
          : response?.data;
        setControlledFilters(result || []);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsFilterInfoLoading(false);
    }
    return [];
  };

  useEffect(() => {
    queryText ? setSearchText(queryText) : setSearchText('');
  }, [queryText]);

  // all and active licenses
  const fetchLicenseDetails = async (
    searchTextProp?: string,
    pageNumberProp?: number,
    pageSizeProp?: number,
    sort?: object,
    lcType?: string,
    reqBody?: object // to reset the request body while switching different license sections
  ) => {
    const licenseType = lcType || selectedLicenseType;
    const updatedRequestBody = reqBody || requestBody;
    if (licenseType === ProducerLicenseType.NEEDS_ATTENTION) {
      fetchNeedsAttentionLicenses(
        searchTextProp,
        pageNumberProp,
        pageSizeProp,
        sort
      );
      return;
    } else if (licenseType === ProducerLicenseType.RENEWALS) {
      fetchRenewalLicenses(searchTextProp, pageNumberProp, pageSizeProp, sort);
      return;
    }
    try {
      setIsLoading((prev) => ({ ...prev, all: true }));
      const agencyId = adminStore.agency?.id;
      if (!agencyId) return;
      if (bearerToken) {
        let filter = {};
        if (searchParams.has('overview')) {
          setSelectedFilters({
            data: DEFAULT_SELECTED_FILTER,
          } as unknown as FiltersType);
          filter = {
            filterGroups: DEFAULT_FILTER,
          };
          searchParams.delete('overview');
          setRequestBody({ ...updatedRequestBody, ...filter });
          setSearchParams(searchParams.toString(), { replace: true });
        }
        if (
          searchParams.has('producerId') &&
          !(isAgentOverview && Object.keys(selectedFilters?.data)?.length > 1)
        ) {
          const producerId = searchParams.get('producerId') || '';
          const producerName = searchParams.get('producerName') || '';
          setSelectedFilters({
            data: DYNAMIC_DEFAULT_SELECTED_FILTER('', {
              value: producerId,
              label: producerName,
            }),
          } as unknown as FiltersType);
          filter = {
            filterGroups: DYNAMIC_DEFAULT_FILTER('', producerId || ''),
          };
          if (!isAgentOverview) searchParams.delete('producerId');
          searchParams.delete('producerName');
          if (producerName || !isAgentOverview)
            setRequestBody({ ...updatedRequestBody, ...filter });
          setSearchParams(searchParams.toString(), { replace: true });
        }
        const defaultRequestBody = {
          agency: agencyId,
          isLatest: true,
        };
        cancelToken && cancelPreviousRequest(cancelToken);
        const cancelTokenSource = axios.CancelToken.source();
        setCancelToken(cancelTokenSource);
        const response: PaginatedSuccessResponse<AllLicenseResponse> =
          await LicensesService.findAllForCurrentAgency(
            {
              ...updatedRequestBody,
              ...defaultRequestBody,
              ...filter,
              ...(sort || sortValue || {}),
              ...(isAgentOverview && {
                agentIds: [agentId],
              }),
            },
            bearerToken,
            pageSizeProp || pageSize,
            pageNumberProp || pageNumber,
            reqBody ? '' : searchTextProp ? searchTextProp : queryText || '',
            reqBody ? '' : searchType ? searchType : searchType || '',
            licenseType === ProducerLicenseType.ACTIVE,
            cancelTokenSource,
            agentId
          );
        if (response) {
          setCancelToken(undefined);
          setTotalCount(response.totalCount);
          setActiveData(response.data);
          setIsLoading((prev) => ({ ...prev, all: false }));
        } else {
          setTotalCount(0);
        }
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        setCancelToken(undefined);
        setIsLoading((prev) => ({ ...prev, all: false }));
      }
      console.error('Error:', error);
    }
  };

  // needs attention
  const fetchNeedsAttentionLicenses = async (
    searchTextProp?: string,
    pageNumberProp?: number,
    pageSizeProp?: number,
    sort?: object,
    reqBody?: object
  ) => {
    try {
      const token = await getAccessTokenSilently();
      setIsLoading((prev) => ({ ...prev, needsAttention: true }));
      let filter = {};
      let payload = reqBody || requestBody;
      if (searchParams.has('overview')) {
        const agent = searchParams.get('producer') || '';
        const state = searchParams.get('state') || '';
        const agentId = searchParams.get('id') || '';

        if (state || agent || agentId) {
          setSelectedFilters({
            data: DYNAMIC_DEFAULT_SELECTED_FILTER(state, {
              value: agentId,
              label: agent,
            }),
          } as FiltersType);
          if (agentId)
            filter = {
              filterGroups: DYNAMIC_DEFAULT_FILTER(state, agentId),
            };
        }
      }

      const response: PaginatedSuccessResponse<LicenseApplicationResponse> =
        await LicensesService.getActionableLicensesForAgency(
          ApplicationTypeEnumInBackend.missing,
          token,
          pageSizeProp || pageSize,
          pageNumberProp || pageNumber,
          reqBody ? '' : searchTextProp ? searchTextProp : queryText || '',
          reqBody ? '' : searchType ? searchType : searchType || '',
          {
            ...(payload || {}),
            ...filter,
            ...(sort || sortValue || {}),
            ...(isAgentOverview && {
              agentIds: [agentId],
            }),
          },
          agentId
        );
      if (response) {
        setCancelToken(undefined);
        setTotalCount(response.totalCount);
        setNeedsAttentionLicenses(response.data);
        setIsLoading((prev) => ({ ...prev, needsAttention: false }));
      } else {
        setTotalCount(0);
      }
    } catch (error: any) {
      if (!axios.isCancel(error)) {
        setCancelToken(undefined);
        setIsLoading((prev) => ({ ...prev, needsAttention: false }));
      }
    }
  };

  const fetchRenewalLicenses = async (
    searchTextProp?: string,
    pageNumberProp?: number,
    pageSizeProp?: number,
    sort?: object,
    reqBody?: object
  ) => {
    try {
      const token = await getAccessTokenSilently();
      setIsLoading((prev) => ({ ...prev, renewal: true }));
      let payload = reqBody || requestBody;

      const response: PaginatedSuccessResponse<LicenseApplicationResponse> =
        await LicensesService.getRenewalLicensesForAgency(
          token,
          pageSizeProp || pageSize,
          pageNumberProp || pageNumber,
          searchTextProp ? searchTextProp : queryText || '',
          searchType ? searchType : searchType || '',
          { ...(payload || {}), ...(sort || sortValue || {}) },
          agentId
        );
      if (response.data) {
        setTotalCount(response.totalCount);
        setRenewalLicenses(response.data);
        setIsLoading((prev) => ({ ...prev, renewal: false }));
      } else {
        setTotalCount(0);
      }
    } catch (error: any) {
      console.error('Error:', error);
      if (!axios.isCancel(error)) {
        setCancelToken(undefined);
        setIsLoading((prev) => ({ ...prev, renewal: false }));
      }
    }
  };

  const removeFilter = (
    keyToRemove: string,
    valueToRemove: string,
    removeKey = false
  ) => {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };

      if (removeKey) {
        delete updatedFilters.data[keyToRemove];
      } else {
        if (Array.isArray(updatedFilters.data[keyToRemove]?.labelValuePair)) {
          const filtersDataArray = updatedFilters.data[keyToRemove]
            .labelValuePair as LabelPairType[];
          const updatedArray = filtersDataArray.filter((element) => {
            return element.value !== valueToRemove;
          });
          if (updatedArray.length > 0) {
            updatedFilters.data[keyToRemove].labelValuePair = updatedArray;
          } else {
            delete updatedFilters.data[keyToRemove];
          }
        } else {
          delete updatedFilters.data[keyToRemove];
        }
      }
      const filterGroups = setFilterGroups(updatedFilters);
      const updatedRequestBody = {
        ...requestBody,
        filterGroups,
      };
      setRequestBody(updatedRequestBody);

      return updatedFilters;
    });
  };

  const deleteQueryText = () => {};

  const handleOnChange = (value: any) => {
    const match = value.match(SEARCH_REGEX);
    if (isEmpty(value)) deleteQueryText();
    if (match) {
      const newSearchType = getSearchTypeByLabel(match[1], searchFilter);
      const newSearchText = match[0].replace(`in: ${match[1]}:`, '');
      if (isEmpty(newSearchText)) {
        deleteQueryText();
        setSearchText('');
        setSearchType('');
      } else {
        setSearchType(newSearchType);
        setSearchText(newSearchText.trimStart());
      }
    } else {
      if (!value.includes(' in ')) {
        setSearchType('');
        setSearchText(value);
      }
    }
  };

  const handleOnSelect = (value: any) => {
    const selectedOption = options.find((option) => {
      return option.value === value;
    });
    if (selectedOption) {
      const [text, type] = selectedOption.value.split(' in ');
      if (
        queryText !== text ||
        searchType !== getSearchTypeByLabel(type, searchFilter)
      ) {
        if (type === 'any') setSearchType('');
        else {
          setSearchType(getSearchTypeByLabel(type, searchFilter));
          setDisplaySearchType(type);
        }
        setQueryText(text);
        setPageNumber(1);
        setSearchText(text);
      }
    }
  };

  const handleOnSearch = (value: any) => {
    if (queryText !== value) {
      const match = value.match(SEARCH_REGEX);
      if (match) {
        const newSearchText = match[2];
        setQueryText(newSearchText);
      } else {
        setQueryText(value);
      }
      setPageNumber(1);
    }
  };

  const pageName = PlausiblePageConstants.AGENCY_ALL_LICENSES_AGENTS;
  const fields = searchFilter.map((filter: any) => filter.filterLabel);
  const options = getOptionsForSearch(fields, searchText);

  useEffect(() => {
    const licenseType = searchParams.get('quickFilter');
    if (licenseType) {
      setSelectedLicenseType(licenseType as ProducerLicenseType);
    }
  }, []);

  useEffect(() => {
    fetchFiltersInfoForFindAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAgentOverview, selectedLicenseType]);

  const QUICK_FILTERS = [
    {
      type: ProducerLicenseType.ALL,
      label: `All`,
    },
    {
      type: ProducerLicenseType.ACTIVE,
      label: `Active (${licenseCount.activeCount})`,
    },
    {
      type: ProducerLicenseType.NEEDS_ATTENTION,
      label: `Needs Attention (${licenseCount.needsAttentionCount})`,
    },
    {
      type: ProducerLicenseType.RENEWALS,
      label: `Upcoming Renewals (${licenseCount.renewalsCount})`,
    },
  ];

  const getTableData = () => {
    if (selectedLicenseType === ProducerLicenseType.NEEDS_ATTENTION)
      return needsAttentionLicenses;
    if (selectedLicenseType === ProducerLicenseType.RENEWALS)
      return renewalLicenses;
    return activeData;
  };

  return (
    <div
      className="reset-icon-size table-layout-v2 relative"
      style={{
        width: '100%',
        height: 'fit-content',
      }}
    >
      {contextHolder}
      <div
        style={{ display: 'flex', columnGap: '10px', margin: '0px 0px 10px' }}
        className="figtree v2-filter-button v2-search"
      >
        <AutoComplete
          options={options}
          style={{ width: '100%' }}
          disabled={Object.values(loading)?.some((d) => d)}
          value={getSearchDisplayText(
            searchType,
            displaySearchType,
            searchText
          )}
          onSelect={handleOnSelect}
          onChange={handleOnChange}
        >
          <Search
            id={IdConstants.SEARCH_INPUT + '-all-licenses-agent-tab'}
            placeholder={
              isAgentOverview
                ? 'Search Licenses by State, LOAs, License Class or License Number'
                : 'Search Licenses by State, Producer, LOAs, License Class or License Number'
            }
            style={{
              width: 372,
            }}
            // allowClear
            onSearch={handleOnSearch}
          />
        </AutoComplete>

        <FilterButton
          {...{
            filterInfoLoading,
            activeData: getTableData(),
            requestBody,
            isLoading: Object.values(loading)?.some((d) => d),
            setIsFilterVisible,
            pageName,
          }}
        />
      </div>
      {/* quick filters */}
      <div
        style={{
          padding: '8px 0',
          gap: '8px',
          display: 'flex',
          flexDirection: 'row',
          overflowX: 'scroll',
          scrollbarWidth: 'none',
        }}
      >
        <div
          style={{
            display: 'flex',
            gap: '8px',
            width: '50%',
          }}
        >
          {QUICK_FILTERS.map((filter) => {
            return (
              <Button
                style={{
                  backgroundColor:
                    selectedLicenseType === filter.type ? '#001F45' : '',
                  color: selectedLicenseType === filter.type ? '#fff' : '',
                }}
                onClick={() => {
                  const licenseType = filter.type;

                  if (selectedLicenseType === licenseType) return;

                  setSelectedLicenseType(licenseType);

                  const newSearchParams = new URLSearchParams(searchParams);

                  newSearchParams.set('quickFilter', filter.type);
                  setSearchParams(newSearchParams);

                  if (
                    licenseType === ProducerLicenseType.ACTIVE ||
                    licenseType === ProducerLicenseType.ALL
                  ) {
                    fetchLicenseDetails(
                      undefined,
                      1,
                      100,
                      undefined,
                      licenseType ? ProducerLicenseType.ACTIVE : 'unset',
                      {}
                    );
                  } else if (
                    licenseType === ProducerLicenseType.NEEDS_ATTENTION
                  ) {
                    fetchNeedsAttentionLicenses(
                      undefined,
                      1,
                      100,
                      undefined,
                      {}
                    );
                  } else if (licenseType === ProducerLicenseType.RENEWALS) {
                    fetchRenewalLicenses(undefined, 1, 100, undefined, {});
                  }
                  // resetting the search and filters
                  setRequestBody({});
                  setSearchType('');
                  setSearchText('');
                  deleteQueryText();
                  setSelectedFilters({ data: {} });
                  setPageNumber(1);
                  setActiveData([]);
                  setNeedsAttentionLicenses([]);
                  setRenewalLicenses([]);
                  if (queryText) setQueryText('');
                }}
                className="agency-license-buttons"
              >
                {filter.label}
              </Button>
            );
          })}
        </div>
      </div>
      {/*  */}
      <RenderFilterChip
        {...{
          selectedFilters: !isAgentOverview
            ? selectedFilters
            : {
                ...selectedFilters,
                data: {
                  ...Object.fromEntries(
                    Object.entries(selectedFilters?.data)?.filter(
                      ([k, v]) => k !== 'Producer'
                    )
                  ),
                },
              },
          removeFilter,
          clearFilters,
          pageName,
        }}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '10px',
          marginTop: 12,
        }}
        className="relative"
      >
        <Table
          columns={tableColumns}
          dataSource={getTableData()}
          pagination={false}
          loading={Object.values(loading)?.some((d) => d)}
          onChange={(pagination, filters, sorter) => {
            const sort = applySort(sorter, fetchLicenseDetails);
            setSortValue(sort);
          }}
          scroll={{
            y: `calc(100vh - ${
              Object.values(selectedFilters?.data)?.length ? '319px' : '285px'
            })`,
          }}
        />
        {!isEmpty(getTableData()) && (
          <Row justify="end" className="table-layout-v2">
            <Pagination
              showSizeChanger={false}
              pageSize={pageSize}
              current={pageNumber}
              onChange={onPaginationChange}
              total={totalCount}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              disabled={Object.values(loading)?.some((d) => d)}
            />
          </Row>
        )}
        <div />
      </div>
      <SimpleFilter
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        setApplyFilter={() => {}}
        setRequestBody={setRequestBody}
        requestBody={requestBody}
        filterLoader={filterInfoLoading}
        visibility={isFilterVisible}
        setVisibility={() => {
          setIsFilterVisible(false);
        }}
        fetchFilters={() => {
          return new Promise((resolve) =>
            resolve([] as FilterFieldsMetaData[])
          );
        }}
        clearFilters={() => {
          clearFilters();
        }}
        controlledFilters={controlledFilters}
        pageName={pageName}
        filterPage={PageConstants.ALL_PRODUCER_LICENSES}
      />
      {/* needs attention */}
      <PaymentConfigModal
        agents={currentAgent ? [currentAgent] : undefined}
        visible={paymentConfigModalVisibility}
        handleVisibility={() => {
          setPaymentConfigModalVisibility(false);
        }}
        successCallback={() => {
          if (adminStore.agency?.id) {
            if (selectedLicenseType === ProducerLicenseType.NEEDS_ATTENTION) {
              fetchNeedsAttentionLicenses(undefined, 1);
            } else if (selectedLicenseType === ProducerLicenseType.RENEWALS) {
              fetchRenewalLicenses(undefined, 1);
            }
            setPageNumber(1);
          }
        }}
      />
      {preApplicationModalRecord && (
        <PreApplicationErrorModal
          showError={showError}
          modalVisibility={preApplicationModalVisibility}
          setModalVisibility={setPreApplicationModalVisibility}
          record={preApplicationModalRecord}
          deleteAppliedLicense={deleteAppliedLicense}
          currentTab={Tab.MISSING_LICENSES}
          setDisableApplyButton={setDisableApplyButton}
          setApplyLicenseErrorModalVisibility={
            setApplyLicenseErrorModalVisibility
          }
          setErrors={setErrors}
          setPayerCallBack={() => {
            setCurrentAgent({
              ...preApplicationModalRecord?.producerDetails,
              id:
                preApplicationModalRecord.agent?.id ||
                preApplicationModalRecord.agent?._id,
            });
            // initial payer missing
            if (
              preApplicationModalRecord.applicationType ===
              ApplicationTypeEnumInBackend.creation
            ) {
              if (
                preApplicationModalRecord.producerDetails?.paymentConfig
                  ?.licenseInitialPayee !== PayeeEnumV2.AGENCY
              )
                setPaymentConfigModalVisibility(true);
              else if (
                // payment type is missing
                !(
                  preApplicationModalRecord.agent?.paymentConfig.paymentType ===
                    PaymentTypeEnum.CAP ||
                  preApplicationModalRecord.agent?.paymentConfig.paymentType ===
                    PaymentTypeEnum.FULL
                )
              )
                setPaymentConfigModalVisibility(true);
            } else {
              // renewal payer missing
              if (
                preApplicationModalRecord.agent?.paymentConfig
                  ?.licenseRenewalPayee !== PayeeEnumV2.AGENCY
              )
                setPaymentConfigModalVisibility(true);
              else if (
                // payment type is missing
                !(
                  preApplicationModalRecord.agent?.paymentConfig.paymentType ===
                    PaymentTypeEnum.CAP ||
                  preApplicationModalRecord.agent?.paymentConfig.paymentType ===
                    PaymentTypeEnum.FULL
                )
              )
                setPaymentConfigModalVisibility(true);
            }
          }}
        />
      )}
      <ApplyLicenseErrorModal
        applyLicenseErrorModalVisibility={applyLicenseErrorModalVisibility}
        setApplyLicenseErrorModalVisibility={
          setApplyLicenseErrorModalVisibility
        }
        errors={errors}
        fetchLicense={
          selectedLicenseType === ProducerLicenseType.RENEWALS
            ? fetchRenewalLicenses
            : fetchNeedsAttentionLicenses
        }
      />
      <LicenseModal
        visible={applicationModalVisible}
        setVisible={setApplicationModalVisible}
        agentLicenseDetails={selectedLicenseDetails}
        portal={PortalType.AGENCY}
        showTransactionDetails={false}
        isProducerAllLicenses={true}
        autoRenewEnabled={selectedLicenseDetails?.agent?.autoRenewEnabled}
      />
    </div>
  );
};
export default withRoles(AllLicensesAgentsTab, [
  RoleType.SUPER_ADMIN,
  RoleType.ADMIN,
]);
