import {
  ArrowLeftOutlined,
  CloseOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  Drawer,
  Modal,
  Row,
  Skeleton,
  Table,
  Tooltip,
  message,
} from 'antd';
import { deleteButtonStyles, duplicateButtonStyles } from './listBuilderUtils';
import { modalDesc, modalTitle } from '../organizationView/organizationUtils';
import { useEffect, useState } from 'react';

import { AgencyService } from '../../../../services/agency.service';
import AppliedFilter from './appliedFilter';
import CopyIconCustom from '../../../../components/common/icons/CopyIconCustom';
import DeleteIconCustom from '../../../../components/common/icons/DeleteIconCustom';
import NoSavedLists from '../../../../assets/images/SavedListBucket.svg';
import { RouteConstants } from '../../../../constants/routes.constants';
import cta from '../../../../assets/icons/expand-lists.svg';
import moment from 'moment';
import { useAuth } from '../../../../auth/authProvider';
import { useNavigate } from 'react-router';

interface SavedListProps {
  onBack: () => void;
}

export interface SavedListDetailsType {
  listName: string;
  id: string;
  hiearchyListFilters: any;
}

const SavedList: React.FC<SavedListProps> = ({ onBack }) => {
  const navigate = useNavigate();
  const [SavedListDetails, setSavedListDetails] = useState<
    SavedListDetailsType[]
  >([]);
  const [isFetching, setIsFetching] = useState(false);
  const { getAccessTokenSilently } = useAuth();
  const [deleteList, setDeleteList] = useState<{
    loading: boolean;
    isOpen: boolean;
  }>({
    loading: false,
    isOpen: false,
  });
  const [cloneList, setCloneList] = useState<{
    loading: boolean;
    isOpen: boolean;
  }>({
    loading: false,
    isOpen: false,
  });
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [previewList, setPreviewList] = useState<SavedListDetailsType | null>(
    null
  );

  const getAllSavedLists = async () => {
    try {
      setIsFetching(true);
      const bearerToken = await getAccessTokenSilently();
      const savedList = await AgencyService.getHiearchyListView(bearerToken);
      setSavedListDetails(savedList as SavedListDetailsType[]);
    } catch (error) {
      console.error(error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    getAllSavedLists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDeleteListById = async () => {
    try {
      setDeleteList((prev) => ({ ...prev, loading: true }));
      const bearerToken = await getAccessTokenSilently();
      const toBeDeletedRows = previewList ? [previewList?.id] : selectedRows;
      const response = await AgencyService.deleteHiearchyListByIds(
        bearerToken,
        toBeDeletedRows
      );
      setSavedListDetails((prev) =>
        prev.filter((d) => !toBeDeletedRows.includes(d.id))
      );
      setDeleteList((prev) => ({ ...prev, loading: false, isOpen: false }));
      setSelectedRows([]);
      setPreviewList(null);
      message?.success(response?.message);
    } catch (error: any) {
      message?.error(error?.response?.data?.message);
      setDeleteList((prev) => ({ ...prev, loading: false }));
    }
  };

  const getListName = (listName: string): string => {
    const isListNamePresent = SavedListDetails?.find(
      (d) => d.listName === listName
    );

    if (!isListNamePresent) return listName;

    return getListName(`${listName} (1)`);
  };

  const onCloneList = async () => {
    try {
      setCloneList((prev) => ({ ...prev, loading: true }));
      const bearerToken = await getAccessTokenSilently();
      const payload = SavedListDetails?.filter((d) =>
        previewList ? d.id === previewList?.id : selectedRows.includes(d.id)
      )?.map((d) => ({ ...d, listName: getListName(`${d?.listName} (1)`) }));
      const response = await AgencyService.createHiearchyListView(
        bearerToken,
        payload
      );
      setSavedListDetails((prev) => [...response, ...prev]);
      setCloneList((prev) => ({
        ...prev,
        loading: false,
        isOpen: false,
      }));
      message.success(
        `${response
          ?.map((d) => d.listName)
          ?.toString()} are duplicated successfully`
      );
      setSelectedRows([]);
    } catch (error: any) {
      console.error(error);
      message?.error(
        error?.response?.data?.message ||
          'Failed to clone lists.Please Try Again.'
      );
      setCloneList((prev) => ({ ...prev, loading: false }));
    }
  };

  const rowSelection = {
    selectedRowKeys: selectedRows,
    onChange: (selectedRowKeys: any[], selectedRows: any[]) => {
      setSelectedRows(selectedRowKeys);
    },
  };

  const columns = [
    {
      title: 'List Name',
      dataIndex: 'listName',
      key: 'listName',
      render: (text: string, data: any) => {
        return (
          <div
            className="sub-heading-small figtree cursor-pointer"
            onClick={() =>
              navigate(RouteConstants.agencyHierarchy.path + `?id=${data?.id}`)
            }
          >
            {text}
          </div>
        );
      },
    },
    {
      title: 'Created by',
      dataIndex: 'agencyName',
      key: 'agencyName',
      render: (text: string) => {
        return <div className="sub-heading-small figtree">{text}</div>;
      },
    },
    {
      title: 'Last Updated on',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text: string) => {
        return (
          <div className="sub-heading-small figtree">
            {text ? moment(text).format('MM/DD/YYYY hh:mm A') : '-'}
          </div>
        );
      },
    },
    {
      dataIndex: 'preview',
      key: 'preview',
      render: (_: string, record: any) => {
        return (
          <div
            className="figtree"
            style={{
              color: '#2286FF',
              textDecoration: 'underline',
              cursor: 'pointer',
              fontWeight: 400,
              fontSize: 12,
              lineHeight: '22px',
            }}
            onClick={() => setPreviewList(record)}
          >
            Preview
          </div>
        );
      },
    },
  ];

  return (
    <div
      style={{
        padding: '12px 0',
        width: '100%',
      }}
      className="table-layout-v2"
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div className="figtree sub-heading">
          <ArrowLeftOutlined
            style={{ marginRight: 12 }}
            className="cursor-pointer"
            onClick={() => onBack()}
          />
          Saved Lists
        </div>
        <div style={{ marginRight: 24, display: 'flex' }}>
          <Tooltip
            title={
              !selectedRows?.length
                ? 'Please select a list to duplicate.'
                : null
            }
          >
            <div className="flex">
              <Button
                className="figtree"
                style={{
                  ...duplicateButtonStyles,
                }}
                onClick={() => {
                  setCloneList((prev) => ({ ...prev, isOpen: true }));
                }}
                disabled={!selectedRows?.length || isFetching}
              >
                <CopyIconCustom />{' '}
                {`Duplicate List${selectedRows?.length > 1 ? 's' : ''}`}
              </Button>
            </div>
          </Tooltip>
          <Tooltip
            title={
              !selectedRows?.length ? 'Please select a list to delete.' : null
            }
          >
            <div className="flex">
              <Button
                className="figtree"
                style={{
                  ...deleteButtonStyles,
                }}
                onClick={() => {
                  setDeleteList((prev) => ({ ...prev, isOpen: true }));
                }}
                disabled={!selectedRows?.length || isFetching}
              >
                <DeleteIconCustom /> Delete
              </Button>
            </div>
          </Tooltip>
        </div>
      </div>
      {isFetching ? (
        <Skeleton />
      ) : SavedListDetails?.length === 0 ? (
        <div
          className="flex-col-center"
          style={{
            width: '100%',
            height: 'calc(100vh - 240px)',
          }}
        >
          <img src={NoSavedLists} alt="no-saved-listes" />
          <Button
            className="figtree"
            style={{
              background: 'var(--Secondary-Secondary, #001F45)',
              color: '#ffffff',
              fontSize: 14,
              lineHeight: '22px',
              fontWeight: 500,
              margin: '12px 0',
            }}
            onClick={() => onBack()}
          >
            <PlusOutlined /> Create Saved List
          </Button>
          <div className="sub-heading-small figtree">
            You haven’t created any view so far!
          </div>
          <div className="sub-heading-small figtree">
            Create your first view to see the list of producers
          </div>
        </div>
      ) : (
        <>
          {' '}
          <Row className="table w-full my-[10px] mx-0 gap-y-[10px]">
            <Col span={24}>
              <Table
                id="id"
                rowClassName={(record) =>
                  selectedRows.includes(record?.id)
                    ? 'selected-producer-row'
                    : ''
                }
                rowKey={(record) => record.id}
                rowSelection={rowSelection}
                columns={columns}
                dataSource={SavedListDetails}
                pagination={false}
              />
            </Col>
          </Row>
        </>
      )}
      <Modal
        width={450}
        title={
          <div
            style={{
              ...modalTitle,
            }}
          >
            {`Do you want to Delete this saved list${
              selectedRows?.length > 1 ? 's' : ''
            }?`}
          </div>
        }
        open={!!deleteList?.isOpen}
        onOk={onDeleteListById}
        confirmLoading={deleteList?.loading}
        closable={!deleteList?.loading}
        onCancel={() => setDeleteList({ isOpen: false, loading: false })}
        okText={
          <div className="flex items-center gap-3">
            <DeleteIconCustom /> Delete
          </div>
        }
        className="ant-modal-footer-flex-justify-end"
        okButtonProps={{
          style: {
            background: '#EB3131',
          },
        }}
        destroyOnClose
      >
        {' '}
        <div style={{ ...modalDesc }}>
          Lists that have been deleted once cannot be revoked again
        </div>
      </Modal>
      <Modal
        title={
          <div
            style={{
              ...modalTitle,
            }}
          >
            {`Do you want to Duplicate this saved list${
              selectedRows?.length > 1 ? 's' : ''
            }?`}
          </div>
        }
        width={450}
        open={!!cloneList?.isOpen}
        onOk={onCloneList}
        confirmLoading={cloneList?.loading}
        closable={!cloneList?.loading}
        onCancel={() =>
          setCloneList({
            isOpen: false,
            loading: false,
          })
        }
        okText="Duplicate"
        okButtonProps={{
          style: {
            background: '#001F45',
            color: '#ffffff',
          },
        }}
        destroyOnClose
      >
        <div style={{ ...modalDesc }}>
          Create a copy of the saved list that you’ve already created
        </div>
      </Modal>
      <Drawer
        destroyOnClose
        open={!!previewList}
        title={null}
        onClose={() => setPreviewList(null)}
        width={1066}
        headerStyle={{ display: 'none' }}
        placement="right"
        bodyStyle={{
          borderRadius: '16px',
          overflow: 'hidden',
        }}
        style={{
          top: '70px',
          borderTopLeftRadius: '16px',
          borderBottomLeftRadius: '16px',
          boxShadow: 'none',
        }}
      >
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
            }}
          >
            <div>
              <div className="sub-heading">{previewList?.listName}</div>
              <div className="sub-heading-small">
                Preview the saved list criteria that you’ve set
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              <Button
                className="figtree"
                style={{
                  ...duplicateButtonStyles,
                }}
                onClick={() => {
                  setCloneList((prev) => ({ ...prev, isOpen: true }));
                }}
              >
                <CopyIconCustom /> Duplicate List
              </Button>
              <Button
                className="figtree"
                style={{
                  ...deleteButtonStyles,
                }}
                onClick={() => {
                  setDeleteList((prev) => ({ ...prev, isOpen: true }));
                }}
              >
                <DeleteIconCustom /> Delete
              </Button>
              <div
                style={{
                  width: 28,
                  height: 28,
                  background: '#F0F0F0',
                  borderRadius: 6,
                  marginLeft: 12,
                  display: 'grid',
                  placeContent: 'center',
                }}
                className="cursor-pointer"
                onClick={() => setPreviewList(null)}
              >
                <CloseOutlined />
              </div>
            </div>
          </div>
          <AppliedFilter appliedFilters={previewList?.hiearchyListFilters} />
        </div>
      </Drawer>
    </div>
  );
};

export default SavedList;
